import type { PostHog } from "posthog-js";
import type { InjectionKey, Ref } from "vue";

export interface AppHeaderPosthog {
  clickEventName: string;
  subscribedEventName: string;
  instance: PostHog | undefined;
  eventNames: Record<string, string>
}
export const PROVIDE_POSTHOG = Symbol() as InjectionKey<Ref<AppHeaderPosthog>>;
