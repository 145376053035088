<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core'
import BaseHeaderComponent from '@repo/ui/src/navigation/BaseHeader.vue'
import type { AppHeaderPosthog } from '@repo/ui/src/const'
import { PROVIDE_POSTHOG } from '@repo/ui/src/const'
import { PH_EVENTS } from "@/lib/const";

defineOptions({
  inheritAttrs: false
})

const { $posthog } = useNuxtApp();
const config = useRuntimeConfig();
const { featuresUrl, corpUrl, docsUrl, buyNowUrl } = config.public;
const { y } = useWindowScroll({ behavior: "smooth" });
const isScrollUp = ref(false);
let lastScrollTop = y.value;

const isTop = computed(() => {
  return !y || y.value <= 0;
});

const posthog = computed<PostHog|undefined>(() => $posthog ? $posthog() : undefined) ;
const posthogInstance = computed<AppHeaderPosthog>(() => {
  return {
    instance: posthog.value,
    clickEventName: PH_EVENTS.subscription.openModal,
    subscribedEventName: PH_EVENTS.subscription.dialogSubscribed,
    eventNames: {
      buyNow: PH_EVENTS.buyNow
    }
  }
})

watch(y, (v) => {
  isScrollUp.value = v < lastScrollTop;
  lastScrollTop = v <= 0 ? 0 : v;
});

provide(PROVIDE_POSTHOG, posthogInstance)
</script>

<template>
  <BaseHeaderComponent
    :is-top="isTop"
    :is-scroll-up="isScrollUp"
    :last-scroll-top="lastScrollTop"
    :url="{ featuresUrl, corpUrl, docsUrl, buyNowUrl }"
  />
</template>
